<app-header></app-header>
<app-sidebar></app-sidebar>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple">
  <p style="font-size: 15px; color: white;text-align: center;">Chargement ... </p>
</ngx-spinner>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item"><a href="javascript: void(0);">Accueil</a></li>
                <li class="breadcrumb-item active">
                  <app-fildariane></app-fildariane>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
      </div>
      <!-- end row -->
      <div class="row">
        <a href="javascript:window.history.back();" class="btn btn-primary waves-effect waves-light btn-sm mb-3"><i class="mdi mdi-chevron-left ml-1"></i> Retour </a>

        <div class="card col-lg-12">
          <div class="card-body">
            <div class="container-fluid mobile_container">
              <div class="row text-left">
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Matricule </span> : {{user_details.matricule}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">NOM </span> : {{user_details.name}}</div>
                <div class="col-md-2 col-lg-3">  <span class="card-title label_info">PRENOM </span> : {{user_details.firstname}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Service </span> : {{user_details.service ? user_details.service : 'Non défini'}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Date de début </span> : {{user_details.start_date === '01/01/1970' ? 'Non défini' : user_details.start_date}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Date de fin </span> : {{user_details.end_date === '01/01/1970' ? 'Non défini' : user_details.end_date}}</div>
                <div class="col-md-2 col-lg-3"> <span class="card-title label_info">Status </span> :
                  <span class="badge badge-success" *ngIf="user_details.active===1">ACTIF</span>
                  <span class="badge badge-danger" *ngIf="user_details.active===0">NON ACTIF</span>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid mobile_container">
            <div class="row head_row text-left">
              <div class="col-md-2 col-lg-3 tt_nom_certif only_pc">
                <i class="bx bxs-check-shield"></i> Certification<br>
                <i class="bx bx-subdirectory-right"></i>Titre du parcours
              </div>
              <div class="col-md-2 col-lg-1 tt_valid_certif only_pc">
                <i class="bx bx-info-circle"></i> Statut du certificat
              </div>
              <div class="col-md-2 col-lg-2 tt_date_certif only_pc">
                <i class="bx bx-info-circle"></i> Statut du parcours
              </div>
              <div class="col-md-2 col-lg-2 tt_valid_certif only_pc">
                <i class="bx bx-time"></i> Durée de validité en mois
              </div>
              <div class="col-md-2 col-lg-2 tt_date_certif only_pc">
                <i class="bx bx-calendar"></i> Date de certification
              </div>
              <div class="col-md-2 col-lg-2 tt_date_certif only_pc">
                <i class="bx bx-calendar"></i> Date de fin de validité
              </div>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="certificats && certificats?.length === 0">
              <p style="text-align: center; margin:20px 0 20px 0;">Cet apprenant ne possède aucun certificat !</p>
            </div>
            <div class="row list_scroll">
              <div class="row text-left certif_wrap" *ngFor="let certification of certificats; let i = index;">
                  <div class="col-md-2 col-lg-3">
                    <div class="label_cert">
                      <span class="only_mobile">Certification : </span>{{certification.certificat_name}}<br>
                    </div>
                  </div>
                  <div class="col-md-2 col-lg-1">
                    <ng-container *ngIf="certification.certificat_statut == 'Valide'">
                      <span class="col_valid valid">Valide</span>
                    </ng-container>
                    <ng-container *ngIf="certification.certificat_statut == 'Non Valide'">
                      <span class="col_valid no_valid">Non valide</span>
                    </ng-container>
                    <ng-container *ngIf="certification.certificat_statut == 'Invalidé'">
                      <span class="col_valid no_valid">Invalidé</span>
                    </ng-container>
                  </div>
                  <div class="col-md-2 col-lg-2"> </div>
                  <div class="col-md-2 col-lg-2"> </div>
                  <div class="col-md-2 col-lg-2"> </div>
                  <div class="col-md-2 col-lg-2"> </div>

                  <ng-container *ngFor="let parcours_result of certification.parcours_result; let k = index">
                    <ng-container *ngIf="parcours_result && parcours_result?.length != 0">

                    <div class="col-md-2 col-lg-3">
                        <span class="only_mobile titre_parcours">Titre du parcours : </span>{{parcours_result.parcour_label}}
                      </div>
                      <div class="col-md-2 col-lg-1"></div>
                      <div class="col-md-2 col-lg-2">{{parcours_result.status_label}}</div>
                      <div class="col-md-2 col-lg-2">
                        <span class="only_mobile">Durée de validité : </span>{{certification.certificat_type_validite === 2 ? certification.certificat_delay + " mois" : certification.certificat_date }}
                      </div>
                      <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <span class="only_mobile">Date de certification : </span>{{parcours_result.date_dernier_passage}}</span>
                      </div>
                      <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                  <ng-container *ngIf="parcours_result.lesson_status=='not validated'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                                  <ng-container *ngIf="parcours_result.lesson_status!='not validated'"><span class="only_mobile">Date de fin de validité : </span>{{parcours_result.date_fin_validite}}</ng-container>
                                </span>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="parcours_result && parcours_result?.length === 0">
                      <div class="col-md-2 col-lg-1"></div>
                      <div class="col-md-2 col-lg-1"></div>
                      <div class="col-md-2 col-lg-1"></div>
                      <div class="col-md-2 col-lg-1"></div>
                      <div class="col-md-2 col-lg-1"></div>
                    </ng-container>
                  </ng-container>

                <ng-container *ngIf="certification.sub_certifications && certification.sub_certifications?.length != 0">
                  <div class="label_ss_cert mt-2 mb-1">Sous-certifications :</div>
                  <ng-container *ngFor="let sub_certifications of certification.sub_certifications; let k = index">
                        <div class="col-md-2 col-lg-3">
                          <div class="label_cert ml-2">
                            <span class="only_mobile">Sous Certification : </span>{{sub_certifications.sub_certificat_name}}<br>
                          </div>
                        </div>
                        <div class="col-md-2 col-lg-1">
                          <ng-container *ngIf="sub_certifications.sub_certificat_statut == 'Valide'">
                            <span class="col_valid valid">Valide</span>
                          </ng-container>
                          <ng-container *ngIf="sub_certifications.sub_certificat_statut == 'Non Valide'">
                            <span class="col_valid no_valid">Non valide</span>
                          </ng-container>
                          <ng-container *ngIf="sub_certifications.sub_certificat_statut == 'Invalidé'">
                            <span class="col_valid no_valid">Invalidé</span>
                          </ng-container>
                        </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <div class="col-md-2 col-lg-2"> </div>
                        <ng-container *ngFor="let sub_certificat_result of sub_certifications.sub_certificat_result; let k = index">

                        <div class="col-md-2 col-lg-3">
                          <div class="ml-2">
                            <span class="only_mobile titre_parcours">Titre du parcours : </span>{{sub_certificat_result.parcour_label}}
                          </div>
                        </div>
                          <div class="col-md-2 col-lg-1"></div>
                          <div class="col-md-2 col-lg-2">
                            <span class="col_valid">
                              {{sub_certificat_result.status_label}}
                            </span>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <span class="only_mobile">Durée de validité : </span>{{sub_certifications.sub_certificat_type_validite === 2 ? sub_certifications.sub_certificat_delay + " mois" : sub_certifications.sub_certificat_date }}
                          </div>

                          <div class="col-md-2 col-lg-2">
                                <span class="col_date">
                                <span class="only_mobile">Date de certification : </span>{{sub_certificat_result.date_dernier_passage}}
                                </span>
                          </div>
                          <div class="col-md-2 col-lg-2">
                            <span class="col_date">
                            <ng-container *ngIf="sub_certificat_result.lesson_status=='not validated'"><span class="col_valid not_attempted"><i class="bx bx-info-circle"></i> NC</span></ng-container>
                              <ng-container *ngIf="sub_certificat_result.lesson_status!='not validated'"><span class="only_mobile">Date de fin de validité : </span>{{sub_certificat_result.date_fin_validite}}</ng-container>
                            </span>
                          </div>
                      </ng-container>
                      </ng-container>
                  </ng-container>
              </div>
            </div>
          </div>

        </div>
        <!-- end row -->
      </div>
      <!-- container-fluid -->
    </div>
  </div>
</div>
<!-- End Page-content -->
<app-footer></app-footer>
